import React from 'react';
import MainPage from "./MainPage";

class SiteContainer extends React.Component<any, any> {

    render(){

        return(
            <div className="SiteContainer">
                <MainPage />
            </div>
        )
    }
}

export default SiteContainer