import React from 'react';

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import * as Utils from "./tools/Utils";

type audioSourcesType = {
    name: string,
    src: string,
    city: string,
    country: string,
    uuid: string,
    radio: string,
    indx: string
}

type mainSourceType={
    city: string,
    country: string,
    video_id: string[],
    start_times: number[],
    radio_url: string[],
    name:string[]
}
type presetObj={
    id: number,
    title: string,
    src: string,
    uuid: string,
}
interface PlayListState {
    currentMusicIndex: number
  }

const playlist : audioSourcesType[] =require('./data/audioSources.json');
const mainList=require('./data/main.json');

const maxMusicIndex = 247; //manually set


class MainPage extends React.Component<any, any> {
    audioInstance: any;

    constructor(props:any) {
        super(props)
        this.state = {
            currentMusicIndex: this.getLastSong(),
        }
    }

    getLastSong = () => {
        let storage = localStorage.getItem("lastSongIndex") || 0;
        if(storage > maxMusicIndex){
            storage = Utils.getRandomArbitrary(0, maxMusicIndex);
        }
        return storage;
    }

    convertJson = () => {
        var count = 0;
        var json = "";
        var substr;
        var subnum = 0;
        var regex = /\d+/;
        const m : mainSourceType = mainList;
        for (var x = 0; x < mainList.length; x++){
            json += this.convertJson0(x, subnum);
            substr = json.substring(json.length - 10, json.length).match(regex)
            subnum = substr ? Number(substr[0]) : 0;
            console.log("substr", subnum);
        }
        return json

    }

    playIndexChange(playIndex:any) {
        console.log("playIndex:", playIndex);
    }

    convertJson0 = (num: number = 0, lastNum: number) => {
        
        const m : mainSourceType = mainList[num];
        var y = "";
        for (var i in m.name){
            //console.log("i:" + m.name[i]);
            let audioSources : audioSourcesType = {
                "name":m.name[i] + " - " + m.city + ", " + m.country,
                "src": m.radio_url[i],
                "radio": m.name[i],
                "city": m.city,
                "country": m.country,
                "uuid":Utils.generateUUID(),
                "indx": (Number(i)+Number(lastNum)).toString()
            }
            y += JSON.stringify(audioSources);
            y += ",";

        }
        return y;
    }

    

    saveToLocalStorage() {
        let id=0;
        var store = this.loadFromLocalStorage();
        var store2 :presetObj[] = store == null ? [] : JSON.parse(store);
        var length = store2?.length;
        var arrz :presetObj[] = [];
        if(length == undefined || store == null){
            id = 1;
            arrz=[];
        }
        else{
            id = length + 1;
            arrz = JSON.parse(store);
        }
        console.log("vals:", store, length);
        var arr :presetObj= {
            id: id,
            uuid: this.audioInstance.id,
            title: this.audioInstance.title,
            src: this.audioInstance.src
        }
        console.log("arrz", arrz);
        if(Array.isArray(arrz)){
            console.log('isarr')
            arrz.push(arr);
        }else{
            console.log('isnotarr')
            arrz = [arrz];
            arrz.push(arr);
        }
        console.log("arrz2", arrz);
        arrz[id -1] = arr;
        localStorage.setItem("Presets", JSON.stringify(arrz));
    }
    loadFromLocalStorage = () => {
        return localStorage.getItem("Presets");
    }
    deleteFromLocalStorage = () => {
        localStorage.removeItem("Presets");
    }

    handleClickPrevious = (): void => {
        let newIndex = this.state.currentMusicIndex === 0 ? playlist.length - 1 : this.state.currentMusicIndex - 1
        localStorage.setItem("lastSongIndex", newIndex.toString());
        this.setState((prevState :any) => ({
          currentMusicIndex: prevState.currentMusicIndex === 0 ? playlist.length - 1 : prevState.currentMusicIndex - 1,
        }))
      }
    
    handleClickNext = (): void => {
        let newIndex = this.state.currentMusicIndex < playlist.length - 1 ? this.state.currentMusicIndex + 1 : 0;
        localStorage.setItem("lastSongIndex", newIndex.toString());
        this.setState((prevState :any) => ({
            currentMusicIndex: prevState.currentMusicIndex < playlist.length - 1 ? prevState.currentMusicIndex + 1 : 0,
        }))
    }
 
    playRandom = (): void => {
        this.setState({
            currentMusicIndex: Utils.getRandomArbitrary(0, maxMusicIndex)
        });
    }

    render(){

        return(
            <div className="MainPage">
                <div>
                    Welcome to Segal.Media
                </div>
                <div>
                    
                </div>

                <div>

                    <AudioPlayer
                        autoPlay
                        src={playlist[this.state.currentMusicIndex].src}
                        autoPlayAfterSrcChange={true}
                        showSkipControls={true}
                        showJumpControls={false}
                        onPlay={e => console.log("onPlay")}
                        onClickPrevious={this.handleClickPrevious}
                        onClickNext={this.handleClickNext}
                        // other props here
                    />

                </div>

                <div>
                    Currently Playing: <b data-apple-music-now-playing>{playlist[this.state.currentMusicIndex].name} </b>
                </div>
                <div>
                    from
                </div>
                <div>
                    {playlist[this.state.currentMusicIndex].src} 
                </div>
                <div>
                    <button data-apple-music-skip-to-next-item className="iosControls" onClick={() => this.handleClickNext()} style={{display:"none"}}>Next</button>
                    <button data-apple-music-skip-to-previous-item className="iosControls" onClick={() => this.handleClickPrevious()} style={{display:"none"}}>Previous</button>
                </div>
                <div>
                    <button  onClick={() => this.playRandom()}>Random Station</button>    
                </div>
                <img style={{maxWidth: "400px", height: "auto", padding:"0.5em 1em" }}src="Segal_Media_Icon_Source.png"/>

                {/*
                <div>
                    <div onDoubleClick={() => this.saveToLocalStorage()}>Save to Preset</div>
                </div>
                <br />
                <div>
                    <div onDoubleClick={() => console.log("Presets:",this.loadFromLocalStorage())}>Read Presets</div>
                </div>
                <br />
                <div>
                    <div onDoubleClick={() => this.deleteFromLocalStorage()}>Delete Presets</div>
                </div>
                */}
                <div>
                    Copyright 2020 - Segal Media by Nicholas Segal
                </div>
            </div>
        )
    }
}

export default MainPage