import React from 'react';

import SiteContainer from "./react/SiteContainer";
import './App.css';

function App() {
  return (
    <div className="App">
      
      <SiteContainer />

    </div>
  );
}

export default App;
